import React, { useEffect, useState } from 'react';
import axios from 'axios';

type BatchStatus = 'In Arbeit' | 'Erledigt';

interface BatchStatusSelectorProps {
  batchName: string | null;
}

const BatchStatusSelector = ({ batchName }: BatchStatusSelectorProps) => {
  const [status, setStatus] = useState<BatchStatus | ''>('');
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const getStatusColor = (status: BatchStatus | '') => {
    switch (status) {
      case 'In Arbeit': return 'bg-yellow-100 text-yellow-800 border-yellow-200';
      case 'Erledigt': return 'bg-green-100 text-green-800 border-green-200';
      default: return 'bg-gray-100 text-gray-800 border-gray-200';
    }
  };

  const fetchBatchStatus = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get('https://toolex.herweck.de/api/update-batch-status/', {
        params: { batch_name: batchName },
        headers: {
          Authorization: `Token ${token}`,
        }
      });
      setStatus(response.data.status);
    } catch (err) {
      console.error('Error:', err);
    }
  };

  const updateBatchStatus = async (newStatus: BatchStatus) => {
    setLoading(true);
    const token = localStorage.getItem('token');

    try {
      await axios.post('https://toolex.herweck.de/api/update-batch-status/', {
        batch_name: batchName,
        status: newStatus
      }, {
        headers: {
          Authorization: `Token ${token}`,
        }
      });
      setStatus(newStatus);
      setIsOpen(false);
    } catch (err) {
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBatchStatus();
  }, [batchName]);

  return (
    <div className="relative inline-block">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`px-4 py-2 border rounded-md shadow-sm font-medium text-sm 
          transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500
          ${getStatusColor(status)} ${loading ? 'opacity-50' : ''}`}
        disabled={loading}
      >
        {status || 'Select status'}
        <span className="ml-2">▼</span>
      </button>
      
      {isOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white border rounded-md shadow-lg">
          {['In Arbeit', 'Erledigt'].map((option) => (
            <button
              key={option}
              onClick={() => updateBatchStatus(option as BatchStatus)}
              className={`w-full px-4 py-2 text-left text-sm hover:bg-gray-50 
                ${option === status ? getStatusColor(option as BatchStatus) : ''}`}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default BatchStatusSelector;