import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BatchSelector from './Batch Table/BatchSelector';
import BatchTable from './Batch Table/BatchTable';
import BatchSummaryComponent from './Batch Table/BatchSummaryComponent';
import ExcelUploader from './Batch Table/ExcelUploader';
import TextUploader from './Batch Table/TextUpload';
import ScoreComponent from './ScoreComponent';
import Header from './Header';
import UploadModal from './UploadModle';
import { XIcon } from 'lucide-react';

import { useSearchParams } from 'react-router-dom';

interface Provider {
    provider_name: string | null;
    provider_id: string | null;
}

interface Classification {
    volume: string;
    amazon_margin: string;
    idealo_margin: string;
    online_since: string;
    avg_monthly_sold: string;
    avg_review: string;
    review_count: string;
    sub_category_rank: string;
    total_offers_idealo: string;
    color: string;
}

interface CurrentOffer {
    id: number;
    status: string;
    price: number | null;
    quantity: number | null;
    provider: Provider | null;
    Current_Amazon_Margin_EUR: number | null;
    Current_Idealo_Margin_EUR: number | null;
    Current_Idealo_Margin_Percentage: number | null;
    Current_Amazon_Margin_Percentage: number | null;
}

interface Product {
    id: number;
    EAN: string;
    ASIN: string | null;
    Title: string | null;
    manufacturer: string | null;
    color: string | null;
    Package_Volume: number | null;
    Package_Weight: number | null;
    Avg_Monthly_Sold: number | null;
    Review_Count: number | null;
    Avg_Review: number | null;
    Current_Amazon_Margin_EUR: number | null;
    Best_Margin_Idealo_EUR: number | null;
    Total_Offers_Idealo: number | null;
    Online_Since: string | null;
    Root_Category_Name: string | null;
    Sub_Category_Name: string | null;
    Latest_Sales_Rank_Sub_Category: number | null;
    current_offer: CurrentOffer | null;
    volume_category: string;
    classifications: Classification;
    score: number;
    alpha_plan_article_id: string | null;
    All_Prices_Idealo: string | number[] | null;

}

interface Batch {
    id: number;
    name: string;
    timestamp: string;
    status: string;
    processing_status: string;
    complete_products: Product[];
    incomplete_products: Product[];
}


const ParentComponent: React.FC = () => {
    const [selectedBatch, setSelectedBatch] = useState<string | null>(null);
    const [batches, setBatches] = useState<string[]>([]);
    const [selectedBatchData, setSelectedBatchData] = useState<Batch | null>(null);
    const [isExcelModalOpen, setIsExcelModalOpen] = useState(false);
    const [isTextModalOpen, setIsTextModalOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
    const [isScoreOpen, setIsScoreOpen] = useState<boolean>(false);
    const token = localStorage.getItem('token');

    const [searchParams] = useSearchParams();

    const [data, setData] = useState<Product[]>([]);
    const [notFoundData, setNotFoundData] = useState<Product[]>([]);

    useEffect(() => {
      const batchFromUrl = searchParams.get('selected');
      if (batchFromUrl) {
        setSelectedBatch(batchFromUrl);
      }
    }, [searchParams]);

    const handleDataUpdate = (newData: Product[], newNotFoundData: Product[]) => {
        setData(newData);
        setNotFoundData(newNotFoundData);
    };

    // Fetch all batch names
    useEffect(() => {
        axios.get('https://toolex.herweck.de/api/batch-names/', {
            headers: {
                Authorization: `Token ${token}`,
            },
            params: {
              type: 'In Arbeit'
            }
        })
        .then((response) => {
            console.log('Batch Names:', response.data);
            setBatches(response.data);
        })
        .catch((error) => {
            console.error('Error fetching batch names: ', error);
        });
    }, []);

    // Fetch specific batch data when selected
    useEffect(() => {
        if (selectedBatch) {
            axios.get(`https://toolex.herweck.de/api/batch/?batch_name=${encodeURIComponent(selectedBatch)}`, {
                headers: {
                    Authorization: `Token ${token}`,
                }
            })
            .then((response) => {
                console.log('Selected Batch Data:', response.data);
                setSelectedBatchData(response.data[0]);
            })
            .catch((error) => {
                console.error('Error fetching batch details: ', error);
            });
        }
    }, [selectedBatch]);

    const handleScoreSubmit = () => {
        setIsScoreOpen(false);
    };

    return (
        <div>
        <Header />
  
        <div className="relative flex">
          {/* Sidebar */}
          <div
            className={`fixed top-0 left-0 h-full bg-gray-100 shadow-lg transition-transform duration-300 ease-in-out 
            ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} w-64 p-4 pt-16 space-y-4`}
          >
            <BatchSelector 
              batches={batches}
              onSelectBatch={(batch) => setSelectedBatch(batch)} 
            />
            
            {/* Upload Buttons */}
            <div className="space-y-3 mt-6">
              <button 
                className="w-full bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-md transition-colors duration-200"
                onClick={() => setIsExcelModalOpen(true)}
              >
                Upload Excel File
              </button>
              
              <button 
                className="w-full bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-md transition-colors duration-200"
                onClick={() => setIsTextModalOpen(true)}
              >
                Upload Text Data
              </button>
            </div>
  
            <button
              className="w-full mt-4 bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded-md transition-colors duration-200"
              onClick={() => setIsScoreOpen(true)}
            >
              Open Score Settings
            </button>
          </div>
  
          {/* Toggle Button */}
          <button 
            className="fixed top-4 left-4 bg-gray-300 text-gray-700 p-2 rounded-md shadow-md hover:bg-gray-400 focus:outline-none w-12 h-12 z-50 transition-colors duration-200"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          >
            {isSidebarOpen ? (
              <XIcon className="h-6 w-6" />
            ) : (
              <div className="space-y-1">
                <span className="block w-6 h-0.5 bg-gray-700"></span>
                <span className="block w-6 h-0.5 bg-gray-700"></span>
                <span className="block w-6 h-0.5 bg-gray-700"></span>
              </div>
            )}
          </button>
  
          {/* Main Content */}
          <div
            className={`flex-1 transition-all duration-300 ease-in-out ${isSidebarOpen ? 'ml-64' : 'ml-0'} p-8`}
          >
            {selectedBatchData && (
              <BatchTable 
                data={selectedBatchData.complete_products}
                batchName={selectedBatch}
                notFoundData={selectedBatchData.incomplete_products}
                onDataUpdate={handleDataUpdate}
              />
            )}
          </div>
        </div>
  
        {/* Upload Modals */}
        <UploadModal
          isOpen={isExcelModalOpen}
          onClose={() => setIsExcelModalOpen(false)}
          title="Upload Excel File"
        >
          <ExcelUploader onSuccess={() => setIsExcelModalOpen(false)} />
        </UploadModal>
  
        <UploadModal
          isOpen={isTextModalOpen}
          onClose={() => setIsTextModalOpen(false)}
          title="Upload Text Data"
        >
          <TextUploader onSuccess={() => setIsTextModalOpen(false)} />
        </UploadModal>
  
        {/* ScoreComponent Modal */}
        <UploadModal
          isOpen={isScoreOpen}
          onClose={() => setIsScoreOpen(false)}
          title="Score Settings"
        >
          <ScoreComponent onSubmit={handleScoreSubmit} />
        </UploadModal>
      </div>
    );
};

export default ParentComponent;