import React, { useState } from 'react';
import axios from 'axios';

interface TextUploaderProps {
  onSuccess?: () => void;
}

const TextUploader = ({ onSuccess }: TextUploaderProps) => {
  const [textData, setTextData] = useState('');
  const [batchName, setBatchName] = useState('');
  const [providerId, setProviderId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextData(event.target.value);
  };

  const handleBatchNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBatchName(event.target.value);
  };

  const handleProviderIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProviderId(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const token = localStorage.getItem('token');
      
      const formData = new FormData();
      formData.append('text', textData);
      formData.append('provider_id', providerId);
      formData.append('data_type', 'text');
      formData.append('batch_name', batchName);

      const response = await axios.post('https://toolex.herweck.de/api/upload-excel/', formData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      alert(`Batch created successfully: ${response.data.message}`);
      setTextData('');
      setBatchName('');
      setProviderId('');
      onSuccess?.(); // Call onSuccess if provided
    } catch (error: any) {
      console.error('Error uploading text data:', error);
      setError(error.response?.data?.message || 'Error uploading data. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-lg font-semibold mb-4">Upload Text Data</h2>
      <form onSubmit={handleSubmit}>
        {error && (
          <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
          </div>
        )}
        
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="textData">
            Enter Text Data:
          </label>
          <textarea
            id="textData"
            value={textData}
            onChange={handleTextChange}
            className="border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline h-48"
            placeholder="Enter your text data here..."
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="batchName">
            Batch Name:
          </label>
          <input
            type="text"
            id="batchName"
            placeholder="Enter batch name"
            value={batchName}
            onChange={handleBatchNameChange}
            className="border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="providerId">
            Provider ID:
          </label>
          <input
            type="text"
            id="providerId"
            placeholder="Enter provider ID"
            value={providerId}
            onChange={handleProviderIdChange}
            className="border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="flex justify-between items-center">
          <button
            type="submit"
            disabled={isSubmitting}
            className="bg-indigo-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-indigo-600 disabled:bg-indigo-300 disabled:cursor-not-allowed"
          >
            {isSubmitting ? 'Uploading...' : 'Upload'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default TextUploader;