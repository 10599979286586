import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Header: React.FC = () => {
  const [username, setUsername] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    // Fetch the user's profile
    axios
      .get('https://toolex.herweck.de/api/user-profile/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setUsername(response.data.username);
      })
      .catch((error) => {
        console.error('Error fetching user profile:', error);
      });
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/logout');
  };

  return (
<header className="bg-gray-100 text-black shadow-lg p-4 flex justify-end items-center space-x-6">


  {/* Navigation Links */}
  <nav className="flex space-x-4">
    <Link
      to="/batch"
      className="hover:bg-gray-200 px-3 py-2 rounded-md transition"
    >
      Batch
    </Link>
    <Link
      to="/batch-list"
      className="hover:bg-gray-200 px-3 py-2 rounded-md transition"
    >
      Batch Übersicht 
    </Link>
  </nav>

  {/* User Profile and Logout */}
  {username ? (
    <p className="text-lg font-semibold">Hallo, {username}</p>
  ) : (
    <p className="text-lg font-semibold">Loading...</p>
  )}
  <button
    onClick={handleLogout}
    className="bg-gray-500 hover:bg-gray-600 px-4 py-2 rounded-md text-white transition"
  >
    Logout
  </button>
</header>


  );
};

export default Header;
